<template>
  <div class="Tasks__View">
    <NewActivity
    ref="taskActivity"
    v-if="isModalOpen"
    type="Task"
    :propPriority="taskReminderObj.Priority"
    :propTitle="'כותרת'"
    :propReminder="getReminder"
    :propAssignedUserId="taskReminderObj.AssignedUserId"
    show-title="true"
    @onClose="onTaskModelClose"
    @onSave="saveTask"
    :propBody="taskReminderObj.Body"
  />
    <EntityListLayout :tabs="tabs">
      <template #title>משימות</template>
      <template #button>
        <b-button v-if="isAdmin" @click="addTask">משימה חדשה</b-button>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import EntityItemsService from "@/services/EntityItemsService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import TaskEntity from "@/views/TaskEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import ProfileService from "@/services/ProfileService";
import NewActivity from "@/components/NewActivity.vue";
import {
  ToastProgrammatic as Toast,
} from "buefy";

export default {
  name: "Tasks",
  data() {
    return {
      tabs: [],
      currProfileInTasks: null,
      isModalOpen: false,
      taskReminderObj: {
        Body: null,
        AssignedUserId: "",
        Priority: 1,
      },
    };
  },
  components: {
    EntityListLayout,
    NewActivity
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    profileId() {
      return this.$store.state.profile.Id;
    },
    tabName() {
      return this.$route.query.tab || "AllTasks";
    },
    getReminder() {
      const date = new Date();
      return date.setDate(date.getDate() + 1);
    },
  },
  created() {
    const taskFields = FieldService.list("tasks");
    const tasksFilters = FilterService.list();

    const sideViewEntity = {
      component: TaskEntity,
      preview: true,
      readOnly: true,
      title: "פרטי משימה",
      map: (item) => item,
      hideRecord: true,
    };

    const fields = [
      { ...taskFields.AssignedUserName, checked: true },
      { ...taskFields.DueDate, checked: true },
      { ...taskFields.EmailReminderDate, checked: false, Sortable: true },
      { ...taskFields.Priority, checked: true },
      { ...taskFields.EntityType, checked: true },
      { ...taskFields.IsOverdue, checked: false },
      { ...taskFields.IsComplete, checked: true },
      { ...taskFields.CompletionDate, checked: false },
      { ...taskFields.CreatorName, checked: false },
      { ...taskFields.Title, checked: true },
      { ...taskFields.Body, checked: false },
    ];

    const tab = {
      id: "AllTasks",
      title: "כל המשימות",
      getDataAsync: this.getAllTasks,
      perPage: 20,
      fields,
      checkableRows: true,
      filters: [
        {
          ...tasksFilters.AssigneeUserId,
          // eslint-disable-next-line no-confusing-arrow
          selected: async () =>
            // eslint-disable-next-line no-return-await
            this.isAdmin ? "" : await this.getProfileInTasks(),
        },
        { ...tasksFilters.TaskType },
        { ...tasksFilters.Priority },
        { ...tasksFilters.SearchFilterType },
      ],
      sortField: "CreateDate",
      sortOrder: "desc",
      sideViewEntity,
      tableActions: (() => {
            const actions = [
              TableActions.updateTask,
            ];
            return actions;
          })(),
    };

    this.tabs = [
      tab,
      {
        ...tab,
        id: "DueTodayTasks",
        title: "מסתיימות היום",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 1;
          setPermanentQuery({
            SearchFilterType: 1,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "DueDueTasks",
        title: "משימות חורגות",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 2;
          setPermanentQuery({
            SearchFilterType: 2,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "UpcomingTasks",
        title: "משימות חדשות",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 3;
          setPermanentQuery({
            SearchFilterType: 3,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "CompletedTasks",
        title: "משימות שהסתיימו",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 4;
          setPermanentQuery({
            SearchFilterType: 4,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
    ];
  },
  methods: {
    async getProfileInTasks() {
      if (!this.currProfileInTasks) {
        const r = await ProfileService.usersTask();
        const me = r.data.find((i) => i.IsMe);
        this.currProfileInTasks = me ? me.Id : null;
      }
      return this.currProfileInTasks;
    },
    addTask() {
      this.isModalOpen = !this.isModalOpen;
    },
    onTaskModelClose() {
      this.isModalOpen = false;
    },
    saveTask(res) {
      this.isModalOpen = false;
      this.taskReminderObj = { ...res };
      this.taskReminderObj.DueDate = new Date(this.taskReminderObj.DueDate);
      this.taskReminderObj.EmailReminderDate = new Date(
        this.taskReminderObj.EmailReminderDate
      );
      EntityItemsService.addTask('Profile', this.profileId, this.taskReminderObj)
        .then(() => {
          this.$emit('onSave');
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          window.location.reload();
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה נכשלה!",
            duration: 4000,
          });
        });
    },
    // getProfileInTasks() {
    //   if (!this.currProfileInTasks) {
    //     ProfileService.usersTask().then((r) => {
    //       const me = r.data.find((i) => i.IsMe);
    //       this.currProfileInTasks = me ? me.Id : null;
    //     });
    //   }
    //   return this.currProfileInTasks;
    // },
    async getAllTasks({ cancelToken, ...rest }) {
      const userId = await this.getProfileInTasks();
      let query = {
        AssigneeUserId: rest.AssigneeUserId ? rest.AssigneeUserId : userId,
      };
      query = this.isAdmin ? {} : query;
      if (typeof rest.AssigneeUserId !== "undefined") {
        query = {};
        const currTab = this.tabs.find((tab) => tab.id === this.tabName);
        const currFilter = currTab.filters.find(
          (f) => f.Query === "AssigneeUserId"
        );
        currFilter.selected = null;
      } // check if filter got clean in porpose or its the default filter
      return EntityItemsService.tasks(
        {
          SearchFilterType: 0,
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>

<style scoped lang="scss">
#NewTask__Component {
  top: 144px;
  left: 582px;
}
</style>
