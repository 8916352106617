var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Tasks__View" },
    [
      _vm.isModalOpen
        ? _c("NewActivity", {
            ref: "taskActivity",
            attrs: {
              type: "Task",
              propPriority: _vm.taskReminderObj.Priority,
              propTitle: "כותרת",
              propReminder: _vm.getReminder,
              propAssignedUserId: _vm.taskReminderObj.AssignedUserId,
              "show-title": "true",
              propBody: _vm.taskReminderObj.Body
            },
            on: { onClose: _vm.onTaskModelClose, onSave: _vm.saveTask }
          })
        : _vm._e(),
      _c("EntityListLayout", {
        attrs: { tabs: _vm.tabs },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("משימות")]
            },
            proxy: true
          },
          {
            key: "button",
            fn: function() {
              return [
                _vm.isAdmin
                  ? _c("b-button", { on: { click: _vm.addTask } }, [
                      _vm._v("משימה חדשה")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "counter",
            fn: function(slotProps) {
              return [
                _vm._v(' סה"כ '),
                _c("b", [_vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }